<template>
  <div id="VideoDetail">
    <Header></Header>
    <div class="position_box">
      <div class="center_position">
        <img
          class="backImg"
          @click="backHome"
          src="../../assets/img/back_home.png"
          alt=""
        />
        <div class="home" @click="backHome">首页</div>
        <div class="current">当前位置：</div>
        <div class="home1" @click="backHome">首页></div>
        <div class="video_center" @click="backvideolist">视频中心></div>
        <div class="video_detail">{{ videoDetail.title }}</div>
      </div>
    </div>
    <div class="vidoe_content">
      <div class="center_box">
        <div class="video_box">
          <div v-if="!autoplayflag" class="position">
            <img
              style="cursor: pointer"
              @click="openVideo"
              src="../../assets/img/autoplay.png"
              alt=""
            />
          </div>
          <img :src="videoDetail.cover" v-if="!autoplayflag" alt="" />
          <video
            id="video"
            controls
            :autoplay="autoplayflag"
            :src="videoDetail.video"
            v-if="autoplayflag"
          ></video>
        </div>
        <div class="title">{{ videoDetail.title }}</div>
        <div class="info">{{ videoDetail.info }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import { getVideoInfo } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
  },
  data() {
    return {
      videoDetail: {},
      autoplayflag: false,
    };
  },
  methods: {
    //点击开始播放视频
    openVideo() {
      this.autoplayflag = true;
    },
    backvideolist() {
      this.$router.go(-1);
    },
    backHome() {
      this.$router.push("/");
    },
  },
  mounted() {
    getVideoInfo({
      id: this.$route.query.id,
    }).then((res) => {
      this.videoDetail = res.data;
    });
  },
  watch: {
    autoplayflag(val) {
      if (val === true) {
        var that = this;
        this.$nextTick(function () {
          var md = document.getElementsByTagName("video")[0];
          md.addEventListener(
            "ended",
            function () {
              that.autoplayflag = false;
            },
            false
          );
        });
      }
    },
  },
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#VideoDetail {
  width: 100%;
  .position_box {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #e5e5e5;
    .center_position {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      .backImg {
        width: 30px;
        height: 30px;
        display: block;
        cursor: pointer;
      }
      .home {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        margin: 0 30px 0 20px;
        cursor: pointer;
      }
      .current {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .home1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .video_center {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        cursor: pointer;
        color: #666666;
      }
      .video_detail {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #225928;
      }
    }
  }
  .vidoe_content {
    width: 100%;
    margin-top: 70px;
    .center_box {
      width: 1200px;
      margin: 0 auto;
      .video_box {
        width: 100%;
        height: 683.86px;
        width: 100%;
        position: relative;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: inline-block;
          outline: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: inline-block;
        }
        .position {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #000000;
          opacity: 0.5;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 84px;
            height: 84px;
            object-fit: contain;
          }
        }
      }
      .title {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 37px;
        color: #333333;
        text-align: center;
        margin: 46px 0 20px 0;
      }
      .info {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        color: #666666;
        text-align: center;
        margin-bottom: 110px;
        white-space: pre-wrap;
      }
    }
  }
}
</style>